import * as React from "react"
import type { HeadFC } from "gatsby"
import VerticalCenter from "../components/VerticalCenter"

import '../base.css'
import Nav from "../components/Nav"
import Footer from "../components/Footer"

const pageStyles = {

}

const headerStyles: React.CSSProperties = {
    fontSize: '400%'
}

const links = [
 
]

const MainPage = () => {
  return (
    <main style={pageStyles}>
        <VerticalCenter>
            <Nav />
            
            <Footer />
        </VerticalCenter>
    </main>
  )
}

export default MainPage

export const Head: HeadFC = () => <title>Joshua LaBue</title>

